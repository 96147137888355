import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { Helmet } from 'react-helmet';

import '../css/Content.css'; // Import your custom CSS for styling Import your custom CSS for styling

const Advantplay = () => {
    const [providers, setProviders] = useState([]);
    const [cards, setCards] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/game_advantplay`);
                // console.log('Fetched data:', response.data);
                setProviders(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    // Function to generate a random initial progress percentage between 60% and 99.9%
    const generateRandomProgress = () => {
        return Math.random() * (99.9 - 60) + 60; // Generates random number between 60 and 99.9
    };

    const getProgressBarColor = (progress) => {
        if (progress < 70) {
            return 'bg-danger'; // Red color for progress < 70
        } else if (progress >= 70 && progress < 90) {
            return 'bg-warning'; // Yellow color for progress between 70 and 89
        } else {
            return 'bg-success'; // Gold color for progress >= 90
        }
    };

    useEffect(() => {
        if (providers.length === 0) return;

        // Initialize cards with random initial progress and color
        const initialCards = providers.map(provider => {
            const progress = generateRandomProgress();
            return {
                ...provider,
                progress,
                progressBarColor: getProgressBarColor(progress)
            };
        });
        setCards(initialCards);

        // Simulate progress update every 15 minutes (900000 milliseconds)
        const interval = setInterval(() => {
            setCards(prevCards => (
                prevCards.map(card => {
                    const newProgress = generateRandomProgress(); // Generate new random progress
                    return {
                        ...card,
                        progress: newProgress,
                        progressBarColor: getProgressBarColor(newProgress)
                    };
                })
            ));
        }, 900000); // 15 minutes interval

        return () => clearInterval(interval);
    }, [providers]);


    return (
        <div className="container">
            <Helmet>
                <title>Advantplay</title>

                <meta property="og:site_name" content="RTP SLOT" />
                <meta property="og:title" content="Advantplay | Play Top Online Slots and Win Big" />
                <meta property="og:url" content="#" />
                <meta property="og:type" content="product" />
                <meta
                    property="og:description"
                    content="Discover the ultimate online slot gaming experience with Advantplay. Enjoy top-rated games, big wins, and a seamless gaming adventure. Join now!" />

                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="language" content="global" />
                <meta name="copyright" content="RTP SLOT" />
                <meta name="author" content="RTP SLOT" />
                <meta name="distribution" content="global" />
                <meta name="publisher" content="RTP SLOT" />
                <meta name="geo.placename" content="global" />
                <meta name="geo.country" content="global" />
                <meta name="geo.region" content="global" />
                <meta name="tgn.nation" content="global" />
                <meta property="og:locale" content="en" />
                <meta itemprop="name" content="Advantplay | Play Top Online Slots and Win Big" />
                <meta itemprop="url" content="#" />
                <meta
                    itemprop="description"
                    content="Discover the ultimate online slot gaming experience with Advantplay. Enjoy top-rated games, big wins, and a seamless gaming adventure. Join now!" />
                <meta name="twitter:title" content="Advantplay | Play Top Online Slots and Win Big" />

                <meta name="twitter:url" content="#" />
                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:description"
                    content="Discover the ultimate online slot gaming experience with Advantplay. Enjoy top-rated games, big wins, and a seamless gaming adventure. Join now!" />

                <meta name="title" content="Advantplay | Play Top Online Slots and Win Big" />
                <meta name="description" content="Discover the ultimate online slot gaming experience with Advantplay. Enjoy top-rated games, big wins, and a seamless gaming adventure. Join now!" />
                <meta name="keywords" content="advantplay, advantplay demo, advantplay slot, slot, igaming, slot online" />
            </Helmet>
            <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-4">
                {cards.map((card, i) => (
                    <div key={i} className="col">
                        <div className="card" data-id={card.id} title={card.title} aria-describedby={`${card.description}`}>
                            {/* <a href={card.href} target="_blank" rel="noopener noreferrer"> */}
                            <img title={card.title} description={card.description} src={card.imageUrl} className="card-img-top" alt={card.altimg} altimg={card.altimg} />
                            {/* </a> */}
                            <div className="card-body">
                                <div className="card-header">
                                    <h1 className="card-title">{card.title}</h1>
                                </div>
                                {card.progress < 70 ? (
                                    <p className="card-text">RTP Drop Down</p>
                                ) : (
                                    <div>
                                        <a href={card.href} className="btn btn-primary" target="_blank" rel="noopener noreferrer">Play Now</a>
                                        {/* <button className="btn btn-secondary ms-2">Demo Button</button> */}
                                    </div>
                                )}
                            </div>
                            <div className={`card-footer ${card.progressBarColor}`}>
                                <small className="text-white">{Math.round(card.progress * 10) / 10}% RTP RATE</small>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <hr />
        </div>
    );
};

export default Advantplay;
