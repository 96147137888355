import React from "react";
import { Routes, Route } from "react-router-dom";

// ! Router - Game Provider
import Pragmatic from '../components/gameContent/Pragmatic'
import Advantplay from "./gameContent/Advantplay";
import Astro from "./gameContent/Astro";
import Cq9 from "./gameContent/Cq9";
import Flowgaming from "./gameContent/Flowgaming";
import Habanero from "./gameContent/Habanero";
import Ionslot from "./gameContent/Ionslot";
import Jdb from "./gameContent/Jdb";
import Joker from "./gameContent/Joker";
import Live22 from "./gameContent/Live22";
import Microgaming from "./gameContent/Microgaming";
import Onetouch from "./gameContent/Onetouch";
import Pgsoft from "./gameContent/Pgsoft";
import Playngo from "./gameContent/Playngo";
import Playtech from "./gameContent/Playtech";
import Rtg from "./gameContent/Rtg";
import Slot88 from "./gameContent/Slot88";
import Spadegaming from "./gameContent/Spadegaming";
import Ygg from "./gameContent/Ygg";
import Content from "../Home";
import About from "../About";

function Router() {
    return (
        <Routes>
            <Route path="/" element={<Content />}>
                <Route path="pragmatic" element={<Pragmatic />} />
                <Route path="advantplay" element={<Advantplay />} />
                <Route path="astro" element={<Astro />} />
                <Route path="cq9" element={<Cq9 />} />
                <Route path="flowgaming" element={<Flowgaming />} />
                <Route path="habanero" element={<Habanero />} />
                <Route path="ionslot" element={<Ionslot />} />
                <Route path="jdb" element={<Jdb />} />
                <Route path="joker" element={<Joker />} />
                <Route path="live22" element={<Live22 />} />
                <Route path="microgaming" element={<Microgaming />} />
                <Route path="onetouch" element={<Onetouch />} />
                <Route path="pgsoft" element={<Pgsoft />} />
                <Route path="playngo" element={<Playngo />} />
                <Route path="playtech" element={<Playtech />} />
                <Route path="rtg" element={<Rtg />} />
                <Route path="slot88" element={<Slot88 />} />
                <Route path="spadegaming" element={<Spadegaming />} />
                <Route path="ygg" element={<Ygg />} />
            </Route>
            <Route path="/about-us" element={<About />} />
        </Routes>
    );
}

export default Router;
