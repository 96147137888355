import React from "react";
import './App.css';

import './components/css/Content.css';
import './components/css/Jackpot.css';

// ! Section
import Header from "./components/Header";

// ! Components
// import Carousel from "./components/Carousel";
import Jackpot from "./components/Jackpot";
import Footer from "./components/Footer";

// ! Router
import Router from "./components/Router";

function App() {

  return (
    <div className="App-wrapper">
      <div className="container-wrap">
        <Header />
        <br />
        <Jackpot />
        <br />
        <hr />
        <Router />
        <hr />
        <Footer />
      </div>
    </div>
  );
}

export default App;