import React from "react";
import { Helmet } from 'react-helmet';

import './App.css';
import './components/css/Carousel.css';

import './components/css/Content.css';
import './components/css/Jackpot.css';

function About() {
  return <div>
    <div className="container">
      <Helmet>
        <title>RTP LIVE SLOT 2024</title>
        <meta property="og:site_name" content="RTP SLOT" />
        <meta property="og:title" content="RTP LIVE SLOT 2024" />
        <meta property="og:url" content="#" />
        <meta property="og:type" content="product" />
        <meta
          property="og:description"
          content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />

        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="language" content="global" />
        <meta name="copyright" content="RTP SLOT" />
        <meta name="author" content="RTP SLOT" />
        <meta name="distribution" content="global" />
        <meta name="publisher" content="RTP SLOT" />
        <meta name="geo.placename" content="global" />
        <meta name="geo.country" content="global" />
        <meta name="geo.region" content="global" />
        <meta name="tgn.nation" content="global" />
        <meta property="og:locale" content="en" />
        <meta itemprop="name" content="RTP LIVE SLOT 2024" />
        <meta itemprop="url" content="#" />
        <meta
          itemprop="description"
          content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />
        <meta name="twitter:title" content="RTP LIVE SLOT 2024" />

        <meta name="twitter:url" content="#" />
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:description"
          content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />

        <meta name="title" content="RTP LIVE SLOT 2024 | Slot Online" />
        <meta name="description" content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />
        <meta name="keywords" content="rtp, rtp live, rtp slot, slot, live rtp, live slot, live online, slot online, rtp online" />
      </Helmet>

      <br />
      <h1>About Us</h1>
      <hr />
      <div className="site-description">
        <h1>RTP LIVE SLOT: Gacor Online Slot Site Slot88 Easy to Win Jackpot Today</h1>
        <p>
          RTP LIVE SLOT is the best choice for those of you who are looking for a gacor slot site with an easy chance of winning the jackpot every day. With a variety of game choices from trusted Slot88 providers, and customer service that is ready to help at any time, as well as fast and safe transactions, your gacor slot playing experience will be more enjoyable and profitable.
        </p>
        <h2>Only on the RTP LIVE SLOT Gacor Slot Site Can You Get the Jackpot</h2>
        <p>
          Are you looking for an online slot site that offers big winning opportunities with abundant jackpots? RTP LIVE SLOT is the answer! Here, you can find various gacor slot games from Slot88 which are known to be easy to win and often provide jackpots every day.
        </p>
        <h3 style={{ paddingLeft: "40px" }}>1. Gacor Slot with High Winrate</h3>
        <p>
          RTP LIVE SLOT is known as a slot site with the highest winrate. This means that your chances of winning in every round are very high. We only choose slot games that are proven to be gacor and often provide jackpots, so you can feel big wins more easily.
        </p>
        <h3 style={{ paddingLeft: "40px" }}>2. The Most Complete Slot88 Games</h3>
        <p>
          At RTP LIVE SLOT, you can find a variety of online slot games from trusted providers such as Slot88. From classic themes to modern themes with innovative features, you can find them all here. Each game is designed with attractive graphics and stunning sound effects, making your playing experience more exciting and enjoyable.
        </p>
        <h3 style={{ paddingLeft: "40px" }}>3. Best Service 24/7</h3>
        <p>
          We understand that player comfort is a top priority. Therefore, RTP LIVE SLOT provides customer service that is ready to assist you anytime, 24 hours a day, 7 days a week. Our support team is always ready to serve and answer all your questions quickly and friendly.
        </p>
        <h3 style={{ paddingLeft: "40px" }}>4. Fast and Safe Transactions</h3>
        <p>
          The security and convenience of transactions are our main focus. At RTP LIVE SLOT, you can make deposits and withdrawals easily and quickly. We work with various banks with a wide selection of safe and trusted payment methods to ensure that every transaction is well protected.
        </p>
        <h3 style={{ paddingLeft: "40px" }}>5. Attractive Bonuses and Promotions</h3>
        <p>
          We offer various attractive bonuses and promotions that you can enjoy every day. Starting from welcome bonuses, deposit bonuses, to cashback, all are available to add to the excitement of your game. With this bonus, your chance to win the jackpot is even greater!
        </p>
      </div>
    </div>
  </div>
}

export default About;
