import React from "react";

function Footer() {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-sm-12 footer-center">
                        <p>We supports any Live RTP Slot provider such as PP99, PG Slot (PG SOFT), Advantplay, Astro, CQ9, Flow Gaming, Habanero, ION Slot, JDB, Joker, Live22, Microgaming, OneTouch, PlayNGo, Playtech, Pragmatic, RTG, Slot88, Spadegaming, YGG, WM Casino, Mega888, EBET, NETENT, Evolution Gaming, Bet Soft, M8 Bet and many others.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 footer-center">
                        <p>© Copyright 2022. All Rights Reserved. ALTERNATIVE RTP SLOT</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
