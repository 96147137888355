import React from "react";
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';

import './components/css/Carousel.css';

import './components/css/Content.css';
import './components/css/Jackpot.css';

// ! Components
import Carousel from "./components/Carousel";

function Content() {


    return (
        <div className="content">
            <Helmet>
                <title>ALTERNATIVE RTP LIVE SLOT UPDATE</title>
                <meta property="og:site_name" content="RTP SLOT" />
                <meta property="og:title" content="38 THAI: ALTERNATIVE RTP LIVE SLOT UPDATE" />
                <meta property="og:url" content="#" />
                <meta property="og:type" content="product" />
                <meta
                    property="og:description"
                    content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta name="language" content="global" />
                <meta name="copyright" content="RTP SLOT" />
                <meta name="author" content="RTP SLOT" />
                <meta name="distribution" content="global" />
                <meta name="publisher" content="RTP SLOT" />
                <meta name="geo.placename" content="global" />
                <meta name="geo.country" content="global" />
                <meta name="geo.region" content="global" />
                <meta name="tgn.nation" content="global" />
                <meta property="og:locale" content="en" />
                <meta itemprop="name" content="38 THAI: ALTERNATIVE RTP LIVE SLOT UPDATE" />
                <meta itemprop="url" content="#" />
                <meta
                    itemprop="description"
                    content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />
                <meta name="twitter:title" content="38 THAI: ALTERNATIVE RTP LIVE SLOT UPDATE" />

                <meta name="twitter:url" content="#" />
                <meta name="twitter:card" content="summary" />
                <meta
                    name="twitter:description"
                    content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />

                <meta name="title" content="38 THAI: ALTERNATIVE RTP LIVE SLOT UPDATE" />
                <meta name="description" content="Check the newest update from alternative RTP Live Slot Website. Get your jackpots and lucky right now." />
                <meta name="keywords" content="38thai, 38 thai, 38 thai slot, rtp, rtp live, rtp slot, slot, live rtp, live slot, live online, slot online, rtp online" />
            </Helmet>
            <Carousel />
            <br />
            <h1>🔥RTP Live Slot Today🔥</h1>
            <hr />
            <Outlet />
            <br />
        </div>
    );
}

export default Content;
