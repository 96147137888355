import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import '../App.css';

const Carousel = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/gameProvider`);
                // console.log('Fetched data:', response.data);
                setItems(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);


    // Duplicate the items array to ensure infinite looping
    const duplicatedItems = [...items, ...items];

    const chunkSize = 4;
    const chunkedItems = [];

    for (let i = 0; i < duplicatedItems.length; i += chunkSize) {
        chunkedItems.push(duplicatedItems.slice(i, i + chunkSize));
    }

    return (
        <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
                {chunkedItems.map((chunk, index) => (
                    <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                        <div className="d-flex justify-content-center">
                            {chunk.map((item, itemIndex) => (
                                <div className="col-6 col-sm-6 col-md-3 col-lg-3" key={item.id || itemIndex}>
                                    <div className="card">
                                        <div className="card-img img-fluid" style={{ backgroundImage: `url(${item.src})` }} ></div>
                                        <div className="card-body">
                                            <h5 className="card-title">{item.caption}</h5>

                                            <p className="card-text visually-hidden">{item.description}</p>
                                            <Link to={item.href} className="btn btn-primary">Check RTP</Link>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default Carousel;
