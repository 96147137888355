import React, { useState, useEffect } from "react";
import '../App.css'; // Import the CSS file

function Jackpot() {
    const [jackpot, setJackpot] = useState(800000000);

    useEffect(() => {
        const interval = setInterval(() => {
            setJackpot(prevJackpot => {
                let newJackpot = prevJackpot + Math.floor(Math.random() * 100000) + 1;
                if (newJackpot > 999899999) {
                    newJackpot = 800000000; // Reset to minimum if it exceeds the maximum
                }
                return newJackpot;
            });
        }, 5000);

        return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);

    const formatNumber = number => {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    return (
        <div className="jackpot-section">
            <div className="jackpot-container d-flex align-items-center justify-content-center flex-wrap">
                <span className="live-jackpot-text me-3">LIVE JACKPOT</span>
                <span className="jackpot-number">{formatNumber(jackpot)}</span>
            </div>
            <a href="https://cutt.ly/EeoSb22g" target="_blank" rel="noopener noreferrer">
                <img
                    src={'https://whitetaoka.com/image/homepage/Alternative-Live-RTP-PP99_1140x170.gif'}
                    alt="Banner"
                    className="responsive-img img-fluid"
                />
            </a>
        </div>
    );
}

export default Jackpot;
