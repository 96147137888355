import React from "react";
import '../App.css';

function Header() {
    return (
        <div className="header">
            <h1 style={{ textAlign: "center" }}>
                <a className="HeaderTitle" href="https://38thai.info">ALTERNATIVE RTP SLOT INFO</a>
            </h1>
            <br />
            <ul className="nav justify-content-center">
                <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/">Home</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="/about-us">About Us</a>
                </li>
                {/* <li className="nav-item">
                    <a className="nav-link" href="blog">Blog</a>
                </li> */}
            </ul>
            <br />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <a className="banner-main" href="https://cutt.ly/EeoSb22g" target="_blank" rel="noopener noreferrer">
                    <img
                        src={'https://whitetaoka.com/image/homepage/Alternative-Live-RTP-PP99_1478x441.jpg'}
                        alt="Banner"
                        className="responsive-img"
                    />
                </a>
            </div>
        </div>
    );
}

export default Header;
